import React from "react";
import IndexPage from "./index"

const IndexPageIT = () => (
  <IndexPage i18n="it"/>
);

export default IndexPageIT;


